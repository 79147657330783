/*
 * @Description: tj组件 - 通用Dialog 全局管理
 * @Author: LXG
 * @Date: 2021-08-18
 * @Editors: LXG
 * @LastEditTime: 2021-08-20
 */

import Vue from 'vue'
import store from '@/store'
import TjCommonDialog from './TjCommonDialog'

const cpn = Vue.extend(TjCommonDialog) // 组件类
const instMap = new Map() // 实例集合

const $tjCommonDialog = {
    /**
     * @description: 创建实例
     * @param {Object} dialogOptions dialog选项
     */
    init(dialogOptions = {}) {
        // console.log('init:', dialogOptions)
        let dialogId = new Date().getTime().toString()
        let inst = new cpn({ propsData: dialogOptions, store }).$mount()
        inst.dialogId = dialogId
        document.querySelector('#app').appendChild(inst.$el)
        instMap.set(dialogId, inst)

        Vue.nextTick(() => {
            inst.open()
        })

        return { dialogId, inst }
    },
    /**
     * @description: 关闭弹窗
     * @param {String|Number} dialogId dialogId
     */
    close(dialogId) {
        let targ = instMap.get(dialogId)
        targ && (targ.close());
    },
    /**
     * @description: 销毁实例
     * @param {String|Number} dialogId dialogId
     */
    destroy(dialogId) {
        let targ = instMap.get(dialogId)
        if (targ) {
            document.querySelector('#app').removeChild(targ.$el)
            targ.$destroy()
            instMap.delete(dialogId)
        }
        // location.reload();
    },
}

export default $tjCommonDialog