<!--
 * @Description: 惠税直达服务专区 - 税务政策订阅
 * @Author: LXG
 * @Date: 2021-08-18
 * @Editors: LXG
 * @LastEditTime: 2021-08-22
-->
<template>
    <div class="sw-policy-subscription">
        <header class="pad-tb-1 pad-rl-2 wrap-header">
            <h2 class="enterprise">{{ cpu_enterprise }}</h2>
            <div>
                <span class="marg-rl-1">订阅自身需求偏好标签</span>
                <el-button
                    size="mini"
                    type="primary"
                    @click="showSsDialog"
                >{{!!subscribedData.length?'修改订阅':'立即订阅'}}</el-button>
            </div>
        </header>
        <FilterGroup class="pad-tb-1 pad-rl-2">
            <FilterItem
                class="search-tag"
                label="当前订阅"
                :needAll="false"
            >
                <div
                    v-for="(item, index) in subscribedData"
                    :key="index"
                    class="bord-r-1 tag-item"
                >
                    {{ item.labelname }}
                </div>
            </FilterItem>
        </FilterGroup>
        <div class="wrap-content">
            <div class="pad-tb-1 pad-rl-2 content-header">
                <div>
                    <el-button
                        class="search-release"
                        :class="{
							'search-release--desc':
								searchInfo.data.releaseTime == 1,
						}"
                        type="text"
                        @click="changeRelease"
                    >
                        <span>印发时间</span>
                        <i class="el-icon-top"></i>
                    </el-button>
                    <!-- <el-checkbox
                        class="search-calculate"
                        v-model="searchInfo.data.canCalculate"
                        @change="changeCalculate"
                    >仅显示可计算的政策</el-checkbox> -->
                </div>
                <div class="searchbar">
                    <FormInput v-model="searchInfo.data.keywords"></FormInput>
                    <el-button
                        size="medium"
                        type="primary"
                        @click="changeKeywords"
                    >搜索</el-button>
                </div>
            </div>
            <div class="content-body">
                <div>
                    <div
                        v-for="(item, index) in listData"
                        :key="index"
                        class="pad-1 bord-r-1 marg-tb-1 marg-rl-2 policy-item"
                    >
                        <div class="pad-rl-1 bord-r-1 policy-zcfs">
                            <span
                                v-for="(proj,index) in cpu_policyProjectList(item)"
                                :key="index"
                                v-show="proj.zcname"
                                class="zcfs-item"
                            >{{proj.zcname}}</span>
                            <!-- {{getDictLabel("sq_policy_zjlx", item.zcfs)}} -->
                        </div>
                        <h3
                            class="policy-name"
                            @click="toPolicyDetail(item)"
                        >{{item.name}}</h3>
                        <p class="policy-content">{{item.content.substr(0,200)}}</p>
                        <div class="policy-infos">
                            <div
                                v-for="(info, index) in policyInfos"
                                :key="index"
                                class="info-item"
                                :style="{ width: info.width }"
                            >
                                <label class="info-label">{{ info.label }}：</label>
                                <div class="info-value">
                                    <template v-if="info.valueFormatter">{{
										info.valueFormatter(item)
									}}</template>
                                    <template v-else>{{
										item[info.valueKey]
									}}</template>
                                </div>
                            </div>
                        </div>
                        <div class="policy_footer">
                            <div style="float: right">
                                <el-button
                                    class="marg-rl-1"
                                    size="mini"
                                    type="primary"
                                    @click="showZcppDialog(item)"
                                >政策匹配</el-button>
                                <a
                                    href="https://12366.chinatax.gov.cn/xiaohui/"
                                    target="_blank"
                                >
                                    <el-button
                                        size="mini"
                                        type="primary"
                                    >在线咨询</el-button>
                                </a>
                            </div>
                            <div class="policy-tag">
                                <label>标签：</label>
                                <div style="flex: 1">
                                    <span
                                        v-for="(
											tag, index
										) in item.policyClassificationValue"
                                        :key="index"
                                        class="marg-rl-2"
                                    >{{ tag }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <el-pagination
                    class="marg-tb-2"
                    style="text-align: center"
                    background
                    layout="prev,pager,next,total,jumper"
                    :current-page.sync="pageInfo.page"
                    :page-size.sync="pageInfo.size"
                    :total="pageInfo.total"
                    @current-change="changePage"
                ></el-pagination>
            </div>
        </div>
        <TjCommonDialog
            ref="ssDialog"
            class="ss-dialog"
        >
            <div class="module">
                <h2>当前已订阅</h2>
                <div
                    class="pad-rl-2"
                    style="overflow:auto;"
                >
                    <el-button
                        style="float: right"
                        type="primary"
                    >清空</el-button>
                    <div class="tag-list">
                        <el-tag
                            v-for="(item, index) in temp_subscribedData"
                            :key="index"
                            class="tag-item"
                            type="danger"
                            closable
                            @close="temp_subscribedData.splice(index,1)"
                        >{{item.labelname}}</el-tag>
                    </div>
                </div>
            </div>
            <div class="module">
                <h2>可选择订阅</h2>
                <div class="pad-rl-2">
                    <el-tag
                        v-for="(item, index) in cpu_subscriptionData"
                        :key="index"
                        class="clickable tag-item"
                        type="danger"
                        @click="temp_subscribedData.push(item)"
                    >{{item.labelname}}</el-tag>
                </div>
            </div>
            <template v-slot:footer>
                <el-button @click="closeSsDialog">取消</el-button>
                <el-button
                    type="primary"
                    @click="confirmSsDialog"
                >确定</el-button>
            </template>
        </TjCommonDialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import $tjCommonDialog from "@/components/tj-common-dialog/index.js"
import {
    FilterGroup,
    FilterItem,
} from "@/views/findList/components/filter/index.js";
import { FormInput } from "@/components/form/index.js";
import { LOGIN_URL, LOGIN_URL_BACK } from "@/store/mutation-types";
import TjCommonDialog from "@/components/tj-common-dialog/TjCommonDialog";

export default {
    name: "SwPolicySubscription",
    components: {
        FilterGroup,
        FilterItem,
        FormInput,
        TjCommonDialog,
    },
    filters: {},
    props: {},
    data() {
        return {
            subscribedData: [], // 已订阅标签数据
            temp_subscribedData: [], // 已订阅标签数据(暂存)
            // 可订阅标签数据
            subscriptionData: [
                // { id: '', labelcode: '0', labelname: '增值税' },
            ],
            searchInfo: {
                data: {
                    tag: "0", // 标签
                    keywords: "", // 关键字
                    releaseTime: 1, // 印发时间排序
                    canCalculate: false, // 是否可计算
                },
            },
            // 政策列表数据
            listData: [],
            policyInfos: [
                {
                    width: "200px",
                    label: "政策等级",
                    valueFormatter: (item) => {
                        if (!item.areaid) return '--';
                        return this.getDictLabel('areaLevel', item.areaid);
                    },
                },
                {
                    width: "400px",
                    label: "发文单位",
                    valueKey: "fbjg",
                },
                {
                    label: "发布日期",
                    valueKey: "publictime",
                },
            ],
            pageInfo: {
                page: 1,
                size: 10,
                total: 0,
            },
        };
    },
    computed: {
        ...mapGetters({
            userInfo: "getUserInfo",
            token: "getToken",
            getDictList: "dictionary/getDictList",
            getDictLabel: "dictionary/getDictLabel",
        }),
        cpu_enterprise() {
            if (!this.userInfo) return "";
            return this.userInfo?.enterpriseInfo?.enterprise || "";
        },
        /**
         * @description: 可选择订阅的标签列表
         * @return {Array}
         */
        cpu_subscriptionData() {
            return this.subscriptionData.filter((cv) => {
                return !this.temp_subscribedData.some(
                    (s) => s.id === cv.id
                );
            });
        },
        cpu_policyProjectList() {
            return function (policy) {
                return policy.cfgPermitItemInfoList || [];
            }
        },
    },
    methods: {
        /**
         * @description: 打开订阅弹窗
         */
        showSsDialog() {
            this.temp_subscribedData = JSON.parse(
                JSON.stringify(this.subscribedData)
            );
            this.$refs["ssDialog"].open();
        },
        /**
         * @description: 关闭订阅弹窗
         */
        closeSsDialog() {
            this.$refs["ssDialog"].close();
        },
        confirmSsDialog() {
            // 至少订阅一个标签
            if (!this.temp_subscribedData.length) {
                this.$message.warning("至少订阅一个标签");
                return;
            }
            this.subscribedData = JSON.parse(
                JSON.stringify(this.temp_subscribedData)
            );
            this.$refs["ssDialog"].close();

            this.listData = [];
            this.pageInfo.total = 0;
            this.pageInfo.page = 1;
            this.queryListData();
            this.postSubscription();
        },
        /**
         * @description: 修改搜索条件 印发时间排序
         */
        changeRelease() {
            this.searchInfo.data.releaseTime =
                this.searchInfo.data.releaseTime ^ 1;

            this.listData = [];
            this.pageInfo.page = 1;
            this.queryListData();
        },
        /**
         * @description: 修改搜索条件 是否可计算
         */
        changeCalculate() {
            this.listData = [];
            this.pageInfo.total = 0;
            this.pageInfo.page = 1;
            this.queryListData();
        },
        /**
         * @description: 修改搜索条件 关键字
         */
        changeKeywords() {
            this.listData = [];
            this.pageInfo.total = 0;
            this.pageInfo.page = 1;
            this.queryListData();
        },
        /**
         * @description: 前往政策详情页面
         * @param {Object} policy 政策
         */
        toPolicyDetail(policy) {
            window.sessionStorage.setItem(
                "policyDetailData",
                JSON.stringify(policy)
            );
            this.$router.push({
                name: "showPolicyDetail",
            });
        },
        /**
         * @description: 弹出政策匹配组件
         * @param {Object} policy 政策
         */
        showZcppDialog(policy) {
            $tjCommonDialog.init({
                title: "政策匹配",
                height: '80vh',
                contentComponent: () => import("./PolicyMatching"),
                contentBind: {
                    zcid: policy.id,
                    policyName: policy.name,
                },
            });
        },
        /**
         * @description: 切换页码
         * @param {Number} page 页码
         */
        changePage(page) {
            this.listData = [];
            this.queryListData();

            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },
        /**
         * @description: 查询税类型及标签
         */
        queryTypeAndTag() {
            // let url = `/label/warehouse/list?industry=国家税务总局漯河市税务局`
            let url = `/label/warehouse/list?industry=${window.encodeURI('国家税务总局漯河市税务局')}`
            this.$httpApi.get(url, {}).then((res) => {
                console.log("queryTypeAndTag:", res);
                if (res.code == 200) {
                    this.subscriptionData = (res.data || []).map((cv) => {
                        return {
                            id: cv.id,
                            labelcode: cv.labelcode,
                            labelname: cv.labelname,
                        };
                    });
                }
            });
        },
        /**
         * @description: 查询当前用户已订阅的标签
         */
        querysubscribedData() {
            // TODO
            // 查询用户已订阅标签
            let url = `/baseLabel/baseLabel/getLabelInfo/${this.userInfo.enterpriseInfo.id}`;
            let params = {};
            this.$httpApi.get(url, params).then((res) => {
                console.log("querysubscribedData:", res);
                if (res.code == 200 && res.data) {
                    this.subscribedData = res.data.map(cv => {
                        return {
                            id: cv.id,
                            labelcode: cv.labelcode,
                            labelname: cv.labelname,
                        };
                    });
                    // 如果用户还没订阅过，主动提示订阅
                    if (!this.subscribedData.length) {
                        this.showSsDialog();
                    } else {
                        this.queryListData();
                    }
                }
            });
        },
        /**
         * @description: 查询政策列表
         */
        queryListData() {
            let url = `/sqzd/policy/policyInfoList?pageNum=${this.pageInfo.page}&pageSize=${this.pageInfo.size}`
            // let url = '/elastic/search/sq-policy'
            let params = {
                fbjg: '国家税务总局漯河市税务局',
                remark: this.subscribedData.map(cv => cv.labelcode).join(','),
                orderBy: this.searchInfo.data.releaseTime == 1 ? 'desc' : 'asc',
                name: this.searchInfo.data.keywords,
            };
            this.$httpApi.post(url, params).then((res) => {
                console.log("queryListData:", res);
                if (res.rows && !!res.rows.length) {
                    this.listData = res.rows;
                    this.pageInfo.total = res.total;
                }
            });
        },
        /**
         * @description: 订阅政策标签
         */
        postSubscription() {
            let url = "/baseLabel/baseLabel/bing";
            let params = this.subscribedData.map((cv) => {
                return {
                    labelid: cv.id,
                    qiyeid: this.userInfo.enterpriseInfo.id,
                };
            });
            this.$httpApi.post(url, params).then((res) => {
                console.log("postSubscription:", res);
                if (res.code == 200) {
                }
            });
        },
    },
    watch: {},
    created() {
        console.log("userInfo:", this.userInfo);
    },
    mounted() {
        setTimeout(() => {
            // 该页面需要登录，若未登录，提示并重定向到 单点登录页面
            // if (!this.token && !this.userInfo) {
            if (!this.token && !this.userInfo) {
                let cf = window.confirm(
                    "登录后即可访问'税务政策订阅'，即将前往登录"
                );
                if (cf) {
                  this.$router.push({ path: "/login" });
                } else {
                    this.$router.go(-1);
                }
                return;
            }
            this.queryTypeAndTag()
            this.querysubscribedData();
        }, 200);
    },
};
</script>

<style lang="scss" scoped>
.wrap-header {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 2rem;
    background-color: #ffffff;
}
.enterprise {
    font-weight: normal;
    color: #333333;
}
::v-deep .filter-group {
    margin-bottom: 2rem;

    .filterItem-label {
        font-size: 1rem;
    }
}
::v-deep .filter-item {
    &.search-tag {
        .filterItem-content {
            gap: 1rem;
        }
        .tag-item {
            padding: 0.1rem 0.5rem;
            border: 1px solid #666666;
        }
    }
}
.wrap-content {
    background-color: #ffffff;
}
.content-header {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d71517;
}
.search-release {
    font-size: 1rem;
    color: $col-theme;

    .el-icon-bottom {
        font-weight: bold;
    }

    &.search-release--desc .el-icon-top {
        transform: rotate(180deg);
    }
}
::v-deep .search-calculate {
    margin-left: 2rem;
    font-size: 1rem;

    .el-checkbox__label {
        line-height: 1rem;
        font-size: 1rem;
    }
}
.searchbar {
    display: -webkit-flex;
    display: flex;
    align-items: center;

    ::v-deep .form-input .el-input__inner {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .el-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
.content-body {
    min-height: 5rem;
    overflow: auto;
}
.policy-item {
    border: 1px solid rgba($col-theme, 0.5);
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;

    .policy-zcfs {
        .zcfs-item {
            display: inline-block;
            border: 1px solid $col-theme;
            color: $col-theme;
        }
    }
    .policy-name {
        color: #000000;
        cursor: pointer;

        &:hover {
            color: $col-theme;
        }
    }
    .policy-content {
        color: #000000;
    }
    .policy-infos {
        display: -webkit-flex;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-top: 5px;

        .info-item {
            display: -webkit-flex;
            display: flex;
            align-items: flex-start;

            .info-value {
                flex: 1;
                min-width: 0;
            }
        }
    }
    .policy_footer {
        padding-top: 5px;
        padding-bottom: 5px;
        border-top: 1px solid rgba($col-theme, 0.5);
        margin-top: 5px;
        overflow: auto;
    }
    .policy-tag {
        display: -webkit-flex;
        display: flex;
        align-items: flex-start;
    }
    &:hover {
        box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.1);
        -webkit-transform: translateY(-2px);
        -moz-transform: translateY(-2px);
        -o-transform: translateY(-2px);
        -ms-transform: translateY(-2px);
        transform: translateY(-2px);
    }
}
::v-deep .ss-dialog {
    .module {
        margin-bottom: 1rem;

        h2 {
            margin-bottom: 0.5rem;
            font-weight: normal;
            color: $col-theme;
        }
    }
    .tag-list {
        margin-bottom: -5px;
        overflow: hidden;
    }
    .tag-item {
        margin-right: 10px;
        margin-bottom: 5px;
        font-size: 1rem;
        color: $col-theme;
    }
}
</style>
