<!--
 * @Description: tj组件 - 通用Dialog
 * @Author: LXG
 * @Date: 2021-08-18
 * @Editors: LXG
 * @LastEditTime: 2021-08-22
-->
<template>
    <el-dialog
        v-setDialogSize="{
            visible:visible,
            minWidth:minWidth,
            height:height
        }"
        :custom-class="cpu_customClass"
        :visible.sync="visible"
        :top="top"
        :width="width"
        :modal="modal"
        :title="title"
        :show-close="showClose"
        @closed="closed"
    >
        <template v-slot:title>
            <slot name="title"></slot>
        </template>
        <slot>
            <template v-if="contentComponent">
                <component
                    :is="contentComponent"
                    ref="content"
                    v-bind="cpu_contentBind"
                ></component>
            </template>
        </slot>
        <template v-slot:footer>
            <slot name="footer">
                <el-button
                    v-for="(item,index) in footBtns"
                    :key="index"
                    :size="item.size"
                    :type="item.type"
                    @click="handleFootBtnClick(item)"
                >{{item.label}}</el-button>
            </slot>
        </template>
    </el-dialog>
</template>

<script>
import $tjCommonDialog from './index.js'

export default {
    name: 'TjCommonDialog',
    components: {},
    directives: {
        setDialogSize: function (el, binding, vm) {
            // console.log('setDialogSize:', el, binding, vm)
            const cfg = binding.value
            if (!cfg.visible) return

            setTimeout(() => {
                const dialog = el.childNodes[0]
                cfg.minWidth && (dialog.style.minWidth = cfg.minWidth);
                dialog.style.height = cfg.height
                if (cfg.height !== 'auto') {
                    dialog.style.maxHeight = '100vh'
                    const dialogHeader = dialog.childNodes[0]
                    const dialogBody = dialog.childNodes[1]
                    const dialogFooter = dialog.childNodes[2]
                    if (dialogBody && dialogBody.style) {
                        let difference = (dialogHeader.clientHeight || 0) + (dialogFooter.clientHeight || 0)
                        dialogBody.style.height = `calc(100% - ${difference}px)`
                    }
                }
            }, 0);
        },
    },
    props: {
        dialogId: {
            type: [String, Number],
            default: ''
        },
        // 距顶部的距离
        top: {
            type: String,
            default: '0'
        },
        // 宽度
        width: {
            type: String,
            default: '50%',
        },
        minWidth: {
            type: String,
        },
        // 高度
        height: {
            type: String,
            default: 'auto'
        },
        // 垂直居中
        verticalCenter: {
            type: Boolean,
            default: true
        },
        // 是否有遮罩层
        modal: {
            type: Boolean,
            default: true
        },
        // 标题
        title: {
            type: String,
            default: ''
        },
        // 显示默认关闭按钮
        showClose: {
            type: Boolean,
            default: true,
        },
        // 内容组件
        contentComponent: {},
        // 内容组件props
        contentBind: {
            type: Object,
            default: () => {
                return {}
            }
        },
        // 底部按钮组
        footBtns: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            visible: false, // 显示状态位
        }
    },
    computed: {
        /**
         * @description: dialog的自定义样式类名
         * @return {String}
         */
        cpu_customClass() {
            let str = 'tj-common-dialog'
            if (this.verticalCenter) str += ' dialog--vertical_center'
            return str
        },
        /**
         * @description: 内容组件的自定义属性
         * @return {Object}
         */
        cpu_contentBind() {
            return {
                dialogInstance: this,
                ...this.contentBind,
            }
        },
    },
    methods: {
        /**
         * @description 点击底部按钮
         * @param {Object} btn 按钮
         */
        handleFootBtnClick(btn) {
            btn.handler && btn.handler.call(this);
        },
        /**
         * @description: 打开dialog
         */
        open() {
            this.visible = true
        },
        /**
         * @description: 关闭dialog
         */
        close() {
            this.visible = false
        },
        /**
         * @description: 关闭dialog结束时回调
         */
        closed() {
            this.dialogId && ($tjCommonDialog.destroy(this.dialogId));
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .tj-common-dialog {
    max-width: 100vw;
    margin-bottom: 0;
    overflow: hidden;

    .el-dialog__header {
        font-size: 16px;
    }
    .el-dialog__close {
        font-size: 24px;
    }
    .el-dialog__body {
        position: relative;
        padding: 20px;
        overflow-y: auto;
    }
}
::v-deep .dialog--vertical_center {
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}
</style>