<template>
    <div class="policyServiceCon">
        <div class="wrap-breadcrumb">
           <img src="~@/assets/images/positionImg.png" alt="" style="">
            <el-breadcrumb separator-class="el-icon-arrow-right" style="font-size:18px;display:inline-block;margin-left:8px">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: 'policyService' }">税惠直达专区</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <!-- 政策服务 -->
        <div class="banner">
            <div class="bannerInfo"></div>
            <div class="bannerImg"></div>
        </div>
        <!-- <div class="listContainer">
            <div class="top">
                <div style="display: flex; align-items: center">
                    <span class="node"></span>
                    <span class="subTitie">政策服务</span>
                </div>
                <div style="display: flex; align-items: center">
                    <div class="input-search-bar">
                        <el-input
                            class="input-search"
                            style="font-size: 16px"
                            v-model.trim="inputKeyWord"
                            placeholder="请输入关键字"
                        >
                        </el-input>
                        <el-button
                            class="input-search-btn"
                            type="primary"
                        ><span style="font-size: 18px"> 搜索</span>
                        </el-button>
                    </div>
                    <el-button
                        type="primary"
                        class="resetBtn"
                    >重置</el-button>
                    <span @click="showFilter">条件筛选 <i class="el-icon-caret-bottom"></i></span>
                </div>
            </div>
            <div
                class="filter"
                v-show="filterVisible"
            ></div>
            <el-table
                :data="tableData"
                class="tableStyle"
                :show-header="false"
            >
                <el-table-column
                    label=""
                    width="1000"
                    show-overflow-tooltip
                    prop="title"
                >
                    <template slot-scope="scope">
                        <div class="column1">
                            <p style="font-size:18px"><i class="dot"></i>{{ scope.row.title }}</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label=""
                    prop="time"
                > </el-table-column>
            </el-table>
            <el-pagination
                style="margin-top: 10px"
                background
                :page-size="5"
                layout="prev, pager, next,total, jumper"
                prev-text="上一页"
                next-text="下一页"
                :total="12"
                @size-change="handleSizeChange"
                @current-change="CurrentChange"
            >
            </el-pagination>
        </div> -->
        <!-- 惠税直达 -->
        <div class="wrap-container">
            <ul
                class="tabbar"
                @click="changeTab"
            >
                <li
                    v-for="(item,index) in tabInfo.list"
                    :key="index"
                    class="tab-item"
                    :class="{'tab--active':tabInfo.active===item.value}"
                    :data-cpn="item.value"
                >{{item.label}}</li>
            </ul>
            <keep-alive>
                <component :is="tabInfo.active"></component>
            </keep-alive>
        </div>
    </div>
</template>

<script>
import SfPolicyLibrary from './sf-policy-library/SfPolicyLibrary'
import SwPolicySubscription from './sw-policy-subscription/SwPolicySubscription'

const tableData = [];
for (let i = 0; i < 5; i++) {
    tableData.push({
        title:
            "河南省科学技术厅 河南省财政厅 关于开展2021年度企业研发投入预算备案工作的通知",
        time: "2021-03-10",
    });
}
tableData.push({
    title:
        "河南省工业和信息化厅 河南省财政厅关于组织开展第一批重点“小巨人”企业和第一批支持国家（或省级）中小企业公共服务示范平台推荐申报工作的通知",
    time: "2021-03-10",
});

export default {
    name: "policyService",
    components: {
        SfPolicyLibrary,
        SwPolicySubscription,
    },
    props: {},
    data() {
        return {
            tableData,
            inputKeyWord: "",
            filterVisible: false,
            tabInfo: {
                active: 'SfPolicyLibrary',
                list: [
                    { value: 'SfPolicyLibrary', label: '税费政策库' },
                    { value: 'SwPolicySubscription', label: '税务政策订阅' },
                ],
            },
        };
    },
    created() {
        this.$store.dispatch('dictionary/commonSetDic', {
            types: [
                'tax_ws', // 我是(哈哈?)
                // 'tax_wyz', // 我要找(哈哈?)
                'sq_policy_zjlx', // 扶持方式(支持方式)
            ]
        })
    },
    mounted() { },
    methods: {
        /**
         * @description: 切换tab
         * @param {Event} e Event
         */
        changeTab(e) {
            // console.log('goQuickEntry:', e)
            if (e.target.nodeName.toLowerCase() === 'li') {
                this.tabInfo.active = e.target.getAttribute('data-cpn')
            }
        },
        handleSizeChange() { },
        CurrentChange() { },
        showFilter() {
            this.filterVisible = !this.filterVisible;
        },
    },
};
</script>

<style lang='scss' scoped>
$width: 1150px;
.policyServiceCon {
    // margin-top: -20px;
    width: 100%;
    min-height: 1070px;
    background: url("~@/assets/images/background.png") no-repeat;
    padding-bottom: 10px;
    background-size: 100% 1070px;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    .banner {
        width: $width;
        height: 254px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        .bannerInfo {
            min-width: 664px;
            height: 105px;
            background: url("~@/assets/images/policyServiceBanner.png")
                no-repeat;
        }
        .bannerImg {
            min-width: 423px;
            height: 254px;
            background: url("~@/assets/images/bannerImg.png") no-repeat;
            background-size: contain;
        }
    }
    .listContainer {
        width: $width;
        margin: 0 auto;
        padding: 20px 40px 0 0;
        background: #fff;
        .top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            .node {
                display: inline-block;
                width: 5px;
                height: 35px;
                background-color: red;
            }
            .subTitie {
                margin-left: 10px;
                font-size: 20px;
                font-weight: 700;
                color: red;
            }
        }
        .input-search-bar {
            display: flex;
            // background-color: lightgreen;
            .input-search {
            }
            ::v-deep .el-input__inner {
                width: 280px;
                height: 40px;
                border-radius: 4px 0 0 4px !important;
            }
            ::v-deep .el-button--primary {
                height: 40px;
            }
        }
        .resetBtn {
            width: 80px;
            height: 40px;
            font-size: 18px;
            margin: 0 10px;
        }
        .filter {
            width: $width;
            min-height: 200px;
            background-color: #fff;
        }
        .tableStyle {
            width: 100%;
            margin-left: 20px;
            .column1 {
                display: flex;
                align-items: center;
                .dotCon {
                    width: 26px;
                }
                .dot {
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: #000;
                    margin-right: 10px;
                }
            }
            .column1:hover .dot {
                background-color: #285ec9;
            }
        }
        ::v-deep .el-table .el-table__row:hover {
            color: #285ec9;
        }
        ::v-deep .el-pagination {
            padding-left: 720px;
        }
    }
}
::v-deep {
    .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: red;
    }
    .el-pagination.is-background .el-pager li:not(.disabled):hover {
        color: #333;
    }
}
.wrap-breadcrumb {
    width: $width;
    height: 52px;
    line-height: 52px;
    margin: 0 auto;
}
.wrap-container {
    width: $width;
    margin: 0 auto;
}
.tabbar {
    position: relative;
    margin-top: -3rem;
    font-size: 1.4rem;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #d71517;
    }
}
.tab-item {
    display: inline-block;
    padding: 0.5rem 1rem;
    color: #d71517;
    cursor: pointer;

    &.tab--active {
        color: #ffffff;
        background-color: #d71517;
    }
}
</style>
