<!--
 * @Description: 惠税直达服务专区 - 税费政策库
 * @Author: LXG
 * @Date: 2021-08-17
 * @Editors: LXG
 * @LastEditTime: 2021-08-21
-->
<template>
    <div class="sf-policy-library">
        <FilterGroup class="pad-tb-1 pad-rl-2">
            <!-- <FilterItem
                v-model="searchInfo.data.iam"
                label="我    是"
                :list="getDictList('tax_ws')"
                :needAll="false"
                valueKey="key"
            ></FilterItem> -->
            <FilterItem
                v-model="searchInfo.data.type"
                label="我要找"
                :list="cpu_typeList"
                valueKey="id"
                labelKey="labelname"
            ></FilterItem>
            <FilterItem
                class="search-tag"
                :needAll="false"
            >
                <div
                    v-for="(item,index) in cpu_tagList"
                    :key="index"
                    class="bord-r-1 tag-item"
                    :class="{'tag--active':searchInfo.data.tag.includes(item.labelcode)}"
                    @click="changeTag(item,index)"
                >{{item.labelname}}</div>
            </FilterItem>
        </FilterGroup>
        <div class="wrap-content">
            <div class="pad-tb-1 pad-rl-2 content-header">
                <div>
                    <el-button
                        class="search-release"
                        :class="{'search-release--desc':searchInfo.data.releaseTime==1}"
                        type="text"
                        @click="changeRelease"
                    >
                        <span>印发时间</span>
                        <i class="el-icon-top"></i>
                    </el-button>
                    <!-- <el-checkbox
                        class="search-calculate"
                        v-model="searchInfo.data.canCalculate"
                        @change="changeCalculate"
                    >仅显示可计算的政策</el-checkbox> -->
                </div>
                <div class="searchbar">
                    <FormInput v-model="searchInfo.data.keywords"></FormInput>
                    <el-button
                        size="medium"
                        type="primary"
                        @click="changeKeywords"
                    >搜索</el-button>
                </div>
            </div>
            <div class="content-body">
                <div>
                    <div
                        v-for="(item,index) in listData"
                        :key="index"
                        class="pad-1 bord-r-1 marg-tb-1 marg-rl-2 policy-item"
                    >
                        <div class="pad-rl-1 bord-r-1 policy-zcfs">
                            <span
                                v-for="(proj,index) in cpu_policyProjectList(item)"
                                :key="index"
                                v-show="proj.zcname"
                                class="zcfs-item"
                            >{{proj.zcname}}</span>
                            <!-- {{getDictLabel('sq_policy_zjlx', item.zcfs)}} -->
                        </div>
                        <h3
                            class="policy-name"
                            @click="toPolicyDetail(item)"
                        >{{item.name}}</h3>
                        <div class="policy-content">{{item.content.substr(0,200)}}</div>
                        <div class="policy-infos">
                            <div
                                v-for="(info,index) in policyInfos"
                                :key="index"
                                class="info-item"
                                :style="{'width':info.width}"
                            >
                                <label class="info-label">{{info.label}}：</label>
                                <div class="info-value">
                                    <template v-if="info.valueFormatter">{{info.valueFormatter(item)}}</template>
                                    <template v-else>{{item[info.valueKey]}}</template>
                                </div>
                            </div>
                        </div>
                        <div class="policy_footer">
                            <div style="float:right;">
                                <el-button
                                    class="marg-rl-1"
                                    size="mini"
                                    type="primary"
                                    @click="toAdvisory(item)"
                                >预约专家团队咨询</el-button>
                                <a
                                    href="https://12366.chinatax.gov.cn/xiaohui/"
                                    target="_blank"
                                >
                                    <el-button
                                        size="mini"
                                        type="primary"
                                    >在线咨询</el-button>
                                </a>
                            </div>
                            <div class="policy-tag">
                                <label>标签：</label>
                                <div style="flex:1;">
                                    <span
                                        v-for="(tag,index) in item.policyClassificationValue"
                                        :key="index"
                                        class="marg-rl-2"
                                    >{{tag}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <el-pagination
                    class="marg-tb-2"
                    style="text-align:center;"
                    background
                    layout="prev,pager,next,total,jumper"
                    :current-page.sync="pageInfo.page"
                    :page-size.sync="pageInfo.size"
                    :total="pageInfo.total"
                    @current-change="changePage"
                ></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { FilterGroup, FilterItem } from '@/views/findList/components/filter/index.js'
import { FormInput } from '@/components/form/index.js'
import $tjCommonDialog from '@/components/tj-common-dialog/index.js'

export default {
    name: 'SfPolicyLibrary',
    components: {
        FilterGroup,
        FilterItem,
        FormInput,
    },
    filters: {},
    props: {},
    data() {
        return {
            searchInfo: {
                data: {
                    iam: '', // 我是
                    type: '', // 我要找(税类型)
                    tag: [], // 标签
                    keywords: '', // 关键字
                    releaseTime: 1, // 印发时间排序
                    canCalculate: false, // 是否可计算
                },
                typeTagData: [], // 类型及标签数据
            },
            // 政策列表数据
            listData: [],
            policyInfos: [
                {
                    width: '200px',
                    label: '政策等级',
                    valueFormatter: (item) => {
                        if (!item.areaid) return '--';
                        return this.getDictLabel('areaLevel', item.areaid);
                    }
                },
                {
                    width: '400px',
                    label: '发文单位',
                    valueKey: 'fbjg',
                },
                {
                    label: '发布日期',
                    valueKey: 'publictime',
                },
            ],
            pageInfo: {
                page: 1,
                size: 10,
                total: 0,
            },
        };
    },
    computed: {
        ...mapGetters({
            userInfo: 'getUserInfo',
            getDictList: 'dictionary/getDictList',
            getDictLabel: 'dictionary/getDictLabel',
        }),
        /**
         * @description: 税类型列表
         * @return {Array}
         */
        cpu_typeList() {
            return this.searchInfo.typeTagData.filter(cv => cv.parentId == '0');
        },
        /**
         * @description: 税类型下的标签列表
         * @return {Array}
         */
        cpu_tagList() {
            // 标签的parentId为父类的id
            let arr = this.searchInfo.typeTagData.filter(cv => cv.parentId !== '0')
            if (!this.searchInfo.data.type) return arr;
            return arr.filter(cv => cv.parentId == this.searchInfo.data.type);
        },
        cpu_policyProjectList() {
            return function (policy) {
                return policy.cfgPermitItemInfoList || [];
            }
        },
    },
    methods: {
        /**
         * @description: 修改搜索条件 标签
         * @param {Object} item 项
         * @param {Number} index 下标
         */
        changeTag(item, index) {
            if (this.searchInfo.data.tag.includes(item.labelcode)) {
                let i = this.searchInfo.data.tag.findIndex(cv => cv === item.labelcode)
                this.searchInfo.data.tag.splice(i, 1)
            } else {
                this.searchInfo.data.tag.push(item.labelcode)
            }

            this.listData = []
            this.pageInfo.total = 0
            this.pageInfo.page = 1
            this.queryListData()
        },
        /**
         * @description: 修改搜索条件 印发时间排序
         */
        changeRelease() {
            this.searchInfo.data.releaseTime = this.searchInfo.data.releaseTime ^ 1

            this.listData = []
            this.pageInfo.page = 1
            this.queryListData()
        },
        /**
         * @description: 修改搜索条件 是否可计算
         */
        changeCalculate() {
            this.listData = []
            this.pageInfo.total = 0
            this.pageInfo.page = 1
            this.queryListData()
        },
        /**
         * @description: 修改搜索条件 关键字
         */
        changeKeywords() {
            this.listData = []
            this.pageInfo.total = 0
            this.pageInfo.page = 1
            this.queryListData()
        },
        /**
         * @description: 前往政策详情页面
         * @param {Object} policy 政策
         */
        toPolicyDetail(policy) {
            window.sessionStorage.setItem('policyDetailData', JSON.stringify(policy))
            this.$router.push({
                name: 'showPolicyDetail',
            })
        },
        /**
         * @description: 前往政策咨询页面
         * @param {Object} policy 政策
         */
        toAdvisory(policy) {
            // window.sessionStorage.setItem('policyDetailData', JSON.stringify(policy))
            // const { href } = this.$router.resolve({
            //     name: 'policyAdvisory',
            //     query: {},
            // })
            // window.open(href, '_blank')

            $tjCommonDialog.init({
                width: '400px',
                showClose: false,
                contentComponent: () => import('./ExpertAdvisory'),
            })
        },
        /**
         * @description: 切换页码
         * @param {Number} page 页码
         */
        changePage(page) {
            this.listData = []
            this.queryListData()

            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        },
        /**
         * @description: 查询税类型及标签
         */
        queryTypeAndTag() {
            // let url = `/label/warehouse/list?industry=国家税务总局漯河市税务局`
            let url = `/label/warehouse/list?industry=${window.encodeURI('国家税务总局漯河市税务局')}`
            this.$httpApi.get(url, {}).then(res => {
                // console.log('queryTypeAndTag:', res)
                if (res.code == 200) {
                    this.searchInfo.typeTagData = res.data || []
                }
            })
        },
        /**
         * @description: 查询政策列表
         */
        queryListData() {
            let url = `/sqzd/policy/policyInfoList?pageNum=${this.pageInfo.page}&pageSize=${this.pageInfo.size}`
            // let url = '/elastic/search/sq-policy'
            let params = {
                fbjg: '国家税务总局漯河市税务局',
                remark: this.searchInfo.data.tag.join(','),
                orderBy: this.searchInfo.data.releaseTime == 1 ? 'desc' : 'asc',
                name: this.searchInfo.data.keywords,
            }
            this.$httpApi.post(url, params).then(res => {
                console.log('queryListData:', res)
                if (res.rows && !!res.rows.length) {
                    this.listData = res.rows
                    this.pageInfo.total = res.total
                }
            })
        },
    },
    watch: {},
    created() {
        this.queryTypeAndTag()
        this.queryListData()
    },
    mounted() { },
}
</script>

<style lang="scss" scoped>
::v-deep .filter-group {
    margin-bottom: 2rem;

    .filterItem-label {
        font-size: 1rem;
        white-space: pre;
    }
}
::v-deep .filter-item {
    padding-top: 10px;
    padding-bottom: 10px;

    &.search-tag {
        .filterItem-content {
            gap: 1rem;
        }
        .tag-item {
            padding: 0.1rem 0.5rem;
            border: 1px solid #666666;
            cursor: pointer;

            &:hover {
                color: #e0240a;
            }
            &.tag--active {
                border-color: #e0240a;
                color: #ffffff;
                background-color: #e0240a;
            }
        }
    }
}
.before {
    height: 10vh;
    line-height: 10vh;
    text-align: center;
    font-size: 1.2rem;
    color: rgba($col-theme, 0.5);
    background-color: #ffffff;
}
.wrap-content {
    background-color: #ffffff;
}
.content-header {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d71517;
}
.search-release {
    font-size: 1rem;
    color: $col-theme;

    .el-icon-bottom {
        font-weight: bold;
    }

    &.search-release--desc .el-icon-top {
        transform: rotate(180deg);
    }
}
::v-deep .search-calculate {
    margin-left: 2rem;
    font-size: 1rem;

    .el-checkbox__label {
        line-height: 1rem;
        font-size: 1rem;
    }
}
.searchbar {
    display: -webkit-flex;
    display: flex;
    align-items: center;

    ::v-deep .form-input .el-input__inner {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .el-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
.content-body {
    min-height: 5rem;
    overflow: auto;
}
.policy-item {
    border: 1px solid rgba($col-theme, 0.5);
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;

    .policy-zcfs {
        .zcfs-item {
            display: inline-block;
            border: 1px solid $col-theme;
            color: $col-theme;
        }
    }
    .policy-name {
        color: #000000;
        cursor: pointer;

        &:hover {
            color: $col-theme;
        }
    }
    .policy-content {
        color: #000000;
    }
    .policy-infos {
        display: -webkit-flex;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-top: 5px;

        .info-item {
            display: -webkit-flex;
            display: flex;
            align-items: flex-start;

            .info-value {
                flex: 1;
                min-width: 0;
            }
        }
    }
    .policy_footer {
        padding-top: 5px;
        padding-bottom: 5px;
        border-top: 1px solid rgba($col-theme, 0.5);
        margin-top: 5px;
        overflow: auto;
    }
    .policy-tag {
        display: -webkit-flex;
        display: flex;
        align-items: flex-start;
    }
    &:hover {
        box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.1);
        -webkit-transform: translateY(-2px);
        -moz-transform: translateY(-2px);
        -o-transform: translateY(-2px);
        -ms-transform: translateY(-2px);
        transform: translateY(-2px);
    }
}
</style>